/**
 * Created by Petr on 09/10/2016.
 */
// the semi-colon before function invocation is a safety net against concatenated
// scripts and/or other plugins which may not be closed properly.
;(function ($) {

    "use strict";

    window.App = function (options) {
        this._init(options);
    };

    $.extend(App.prototype, {
        _options: {
            navHeight: 50,
            navContainerClass: '.section-navigation',
            navContainer: $('.section-navigation')
        },

        responsiveMenu: function() {
            var $button = $('.menu-toggle'),
                $menus = $('.mobile-menu, .submenu-items');

            $button.on('click', function(event) {
                event.preventDefault();

                var $menu = $($(this).data('target'));

                $menu.toggle();
                $(this).toggleClass('toggled-on');
            });


            $('a', $menus).not('.external').on('click', function(event) {
                //event.preventDefault();

                if($button.css('display') === 'block') {
                    $menus.hide();
                    $button.removeClass('toggled-on');
                }

            });

            $(window).on('resize', function(event) {
                event.preventDefault();

                if($button.css('display') === 'block') {
                    $menus.hide();
                    $button.removeClass('toggled-on');
                } else{
                    $('.submenu-items').show();
                }
            });
        },

        navigationHeight: function() {

            this.options.navHeight = this.options.navContainer.height();
        },


        _init: function (options) {
            var $app = this;

            this.options = $.extend({}, this._options, options || {});

            this.responsiveMenu();

        }
    });

})(jQuery);